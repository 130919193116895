export default class Tab {
    index: number;
    current: boolean;
    disabled: boolean;
    titleKey: string;

    constructor(titleKey: string, current: boolean, disabled: boolean, index: number) {
        this.titleKey = titleKey;
        this.current = current;
        this.disabled = disabled;
        this.index = index;
    }
}
